import React, {useContext, useState} from 'react';
import {useMutation, useQuery} from '@apollo/client'
import GenericListComponent from "./GenericListComponent";
//import styles from '../Navbar/Navbar.module.css';
import styles from './GenericList.module.css';
import {Button, ButtonGroup, Container, Row, Col, Spinner, DropdownItem, NavLink, Badge} from "reactstrap";
import {
  ADMIN,
  APPROVED,
  COMPLETED,
  CONSOLIDATED,
  VERIFIED,
  REJECTED,
  ROLES_TO_SEE_STATUS,
  ROLES_TO_SEE_STATUS_RESTRICTED,
  TO_HIDE_FROM_PROJECT,
  FLAG_COLS,
} from "../Helpers/constants";
import ModalContainer from "../Modal/ModalContainer";
import {useTranslation,} from 'react-i18next';
import {getStructureQuery} from "../Helpers/mutation";
import {checkStatus, getTableStructure, getType, capitalize, keyValue} from "../Helpers/functions";
import NumberFormat from 'react-number-format';
import {TO_HIDE} from "../Helpers/constants";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {ProjectContext} from "../ProjectProvider";

import ConfirmationModal from "../ConfirmationModal";
import {
  CLONEORGANIZATION,
  CONSOLIDATE_ORG_VERSION,
  DOIMPORT,
  REQUESTFINANCIALSV2,
  SETFINANTIALSTATUS,
  SNAPSHOT_ORG_VERSION,
  UPDATESTATUS
} from "../GraphQl/mutation";
import {
  FIND_PROJECT,
  GET_LOGIN_DATA,
  GET_PRJ_INFO, GET_STRUCTURE_INFO,
} from "../GraphQl/query";

import BudgetModal from "../Modals/Budgets/BudgetModal";
import SubmitModal from "../SubmitModal/SubmitModal";
import ReasonCodeModal from "../Modals/ReasonCodeModal/ReasonCodeModal";
import {
  statusFilter,
  statusFilterMethod,
  currencyFilterMethod,
  OrgStatusFilter,
  BooleanFilter, BooleanFilterMethod,
  BooleanDefaultFilter, BooleanDefaultFilterMethod
} from "../Helpers/filters";
import CommentModal from "../Modals/CommentModal/CommentModal";
import ManageUserModal from "../Modals/ManageUserModal/ManageUserModal";
import {ITEM, MANAGERPAGE, ORGVERSION, PRJ} from "../../navigation/Routes";
import AlertComponent from "../ModelItemsForm/AlertComponent";
import {setSortMethod} from "../Helpers/sortFunctions";
import {
  applyTranslate, changeStatus,
  getChangeStatusBtn,
  getDetailLink, getFinancialRequest,
  getMomentDate, getNonStatuses, getOnCompleteOptions, handleSelectAll,
  setColMapper
} from "../Helpers/GenericListHelpers/genericListFunctions";
import {
  getCell,
  getCheckboxInput,
  getCheckboxInputAccessor,
  getClickableCell,
  getCloneButton,
  getClonedCell,
  getDeleteButton,
  getErrorButton,
  getUnitsButton,
  getBudgetButton,
  getImgCell,
  getUserManageButton,
  getLeftAlignCell,
  getLinkText,
  getNumberCell,
  getPercentageCell,
  getStatusBoxCell,
  getUpdateButton,
  getUrlText,
  getViewButton,
  getReopenButton,
  getCenterAlignCell
} from "../Helpers/GenericListHelpers/genericListElements";
import ProjectVersionModal from '../Modals/ProjectVersionModal/ProjectVersionModal';
import ProjectUnitModal from '../Modals/ProjectUnitModal/ProjectUnitModal';
import {DOWNLOAD_URL} from "../GraphQl";
import i18n from "i18next";
import ModalComponent from "../Modal/ModalComponent";



const token = localStorage.getItem('token');

const headerStyle = {fontWeight: 'bold'};

const prepare = (setValues,
                 toggleBudgetState,
                 toggleModalState,
                 doApprove,
                 structureName,
                 schema,
                 detailsBtnLabel,
                 linkFunction,
                 secondLink,
                 secondDetailsBtn,
                 budgetLink,
                 headersName,
                 parentName,
                 toggleConfModalState,
                 isUploadEnabled,
                 toggleSubmitModalState,
                 status,
                 setRows,
                 selectedRows,
                 toggleCommentModalState,
                 userRole,
                 spinnerStatus,
                 t,
                 doSubmitMutation,
                 doCloneOrg,
                 instCode,
                 setIsLoading,
                 history,
                 isProjectManagerPage,
                 doCloneOrgVersionManager,
                 doReopenOrg,
                 isConsolidated,
                 isVerified,
                 toggleManageUserModalState
) => {
  
  //const error = new Error("");
  //console.log(error.stack);
  
  const Cell = row => getCell(row);
  
  const clickableCell = row => getClickableCell(spinnerStatus, row, t, setValues, structureName, toggleCommentModalState);
  
  const ImageCell = row => getImgCell(row);
  
  const leftAlign = row => getLeftAlignCell(row);
  
  const centerAlign = row => getCenterAlignCell(row);
  
  const linkText = row => getLinkText(row);
  
  const isClonedCell = row => getClonedCell(row);
  
  const UrlCell = row => getUrlText(row);
  
  const DateCell = row => {
    return <div
      style={{marginTop: '7px'}}>{row.value ? getMomentDate(row) : ""}</div>
  };
  
  const numberCell = row => getNumberCell(row);
  
  const percentageCell = row => getPercentageCell(row);
  
  const CellDeleteButton = row => getDeleteButton(isUploadEnabled, status, row, t, toggleConfModalState, setValues);
  
  const actionBtn = row => {
    // PM 20240322 Quando lo stato è Completed e la rendicontazione è verificata, posso riapire solo se è già stata clonata
    if(structureName === "OrganizationVersion" && row.original.status === t(COMPLETED) && !isConsolidated && (!isVerified || row.original.parent)){
      return getReopenButton(structureName, history, row, doReopenOrg, t);
    }
    return getCloneButton(structureName, history, row, doCloneOrgVersionManager, t);
  };
  
  const CellUpdateBtn = row => getUpdateButton(isUploadEnabled, status, row, t, structureName, toggleModalState, history, setValues);
  
  const CellViewBtn = row => getViewButton(structureName, toggleModalState, history, row, setValues);
  
  const ToErrorBtn = row => getErrorButton(row, secondLink);
  
  const ToUnitsBtn = row => getUnitsButton(row, secondLink, history);
  
  const ToBudgetBtn = row => getBudgetButton(row, toggleBudgetState, history);
  
  const OpenUserButton = row => getUserManageButton(row, toggleManageUserModalState);
  
  // PM 20200506
  const getStatusButton = row => {
    return getChangeStatusBtn(toggleSubmitModalState, setValues, row, userRole, doSubmitMutation, doCloneOrg, instCode, setIsLoading, toggleConfModalState);
  };
  
  
  const CellCheckbox = row => getCheckboxInput(row);
  const CellCheckboxAccessor = accessor => row => getCheckboxInputAccessor(accessor, row);
  
  const StatusBox = row => getStatusBoxCell(selectedRows, row, setRows);
  
  function getDetailCell(col) {
    
    // Luigi: Link Creation Proxy
    // Luigi: qui si perfezionano i link
    
    // console.log("---");
    // console.log("col.accessor: ", col.accessor);
    // console.log("history: ", history);
    
    return getDetailLink(col, headersName, linkFunction, detailsBtnLabel, secondLink, secondDetailsBtn, budgetLink, structureName, isProjectManagerPage, history, toggleBudgetState);
  }
  
  const colMapper = (col) => {
    
    
    // Luigi: Dovrebbe venire chiamata una volta per tabella da visualizzare
    // una chiamata per ogni colonna in header..
    
    // at colMapper (bundle.js:4929:19)
    // at Array.map (<anonymous>)
    // at setColMapper (genericListFunctions.js:307:1)
    // at prepare (bundle.js:5135:105)
    // at GenericListContainer (bundle.js:6080:20)
    
    // poi si finisce si
    // GenericListComponent = ({data, columns
    
    const type = getType(col, structureName);
    
    // console.log("type: ", type);
    // console.log("col: ", col);
    
    const mappedCol = {...col};
    
    // const error = new Error('');
    // console.log(error.stack);
    
    switch (type) {
      case "Date":
        if (TO_HIDE_FROM_PROJECT.includes(mappedCol.accessor) && (structureName === "Project" && !isProjectManagerPage))
          mappedCol.show = false;
        else if (structureName === "OrganizationVersion" && col.accessor === "lastModifiedDate") {
          mappedCol.Cell = DateCell;
        } else if (structureName === "ValidationRule" && userRole !== ADMIN)
          mappedCol.show = false;
        else
          mappedCol.Cell = DateCell;
        if (structureName === "ValidationRule") mappedCol.width = 300;
        break;
      case "Int":
      case "Integer":
        if (TO_HIDE.includes(mappedCol.Header) || mappedCol.visible === 'hidden') {
          mappedCol.show = false;
        } else if (mappedCol.accessor === 'ID') {
          mappedCol.minWidth = 50;
        } else if (structureName === "SubCategoryTotalDto")
          mappedCol.show = false
        mappedCol.Cell = Cell;
        break;
      case "Delete":
        if (isUploadEnabled && structureName!=="ProjectUnits")
          mappedCol.Cell = CellDeleteButton;
        else
          mappedCol.show = false;
        break;
      case "Boolean":
        if ((isProjectManagerPage && mappedCol.accessor === 'parent') || structureName === "OrganizationVersion")
          mappedCol.show = false;
        else if (mappedCol.accessor === 'parent') {
          mappedCol.Cell = isClonedCell;
          mappedCol.width = 50;
        }
        else if (structureName !== "ValidationRule" && mappedCol.accessor === 'active') {
          mappedCol.filterMethod = BooleanFilterMethod;
          mappedCol.Filter = BooleanFilter
          mappedCol.Cell = CellCheckbox;
        } else if (structureName === "ValidationRule" && userRole !== ADMIN)
          mappedCol.show = false;
        else if (FLAG_COLS.includes(mappedCol.accessor)) {
          mappedCol.Cell = CellCheckboxAccessor(mappedCol.accessor);
          mappedCol.filterMethod = BooleanDefaultFilterMethod;
          mappedCol.Filter = BooleanDefaultFilter;
        } else
          mappedCol.Cell = CellCheckbox;
        break;
      case "Update":
        if (isUploadEnabled)
          mappedCol.Cell = CellUpdateBtn;
        else
          mappedCol.show = false;
        break;
      case "View":
        mappedCol.Cell = CellViewBtn;
        break;
      case "Error":
        mappedCol.Cell = ToErrorBtn;
        break;
      case "Submit":
        mappedCol.Cell = getStatusButton;
        break;
      case "String":
        if (mappedCol.accessor === "items")
          mappedCol.show = false;
        else if (isProjectManagerPage && mappedCol.accessor === "status")
          mappedCol.show = false;
        else if (isProjectManagerPage && mappedCol.accessor === 'parent')
          mappedCol.Cell = leftAlign;
        // PM20210705
        else if (/*(structureName === "Project" && mappedCol.accessor === "description") || */mappedCol.visible === 'hidden')
          mappedCol.show = false;
        // PM20210705
        else if (structureName === "Project" && mappedCol.accessor === "cup")
          mappedCol.show = false;
        else if (mappedCol.accessor === "validationResult") {
          mappedCol.Cell = ImageCell;
          mappedCol.width = 150;
        } else if (mappedCol.accessor === 'status' && (structureName === 'Financial' || structureName === 'SubCategoryTotalDto' || structureName === "Project")) {
          mappedCol.filterMethod = statusFilterMethod;
          mappedCol.Filter = structureName === "Project" ? OrgStatusFilter : statusFilter;
          mappedCol.Cell = structureName === "Project" ? leftAlign : clickableCell;
        } else if (structureName === "ValidationRule" && (mappedCol.accessor === "expression" || mappedCol.accessor === "messagePrint"))
          userRole === ADMIN ? mappedCol.Cell = leftAlign : mappedCol.show = false;
        else if (mappedCol.accessor === "reasonCode" && structureName === 'Financial') {
          ROLES_TO_SEE_STATUS.includes(userRole) ? mappedCol.Cell = leftAlign : mappedCol.show = false;
        } else if(mappedCol.accessor.includes('_url')) {
          mappedCol.Cell = UrlCell;
        } else
          mappedCol.Cell = leftAlign;
        break;
      case "BigDecimal":
        mappedCol.filterMethod = currencyFilterMethod;
        mappedCol.sortable = true
        mappedCol.sortMethod = setSortMethod()
        if (mappedCol.visible === 'hidden')
          mappedCol.show = false;
        else if (mappedCol.accessor.includes('_perc'))
          mappedCol.Cell = percentageCell;
        else
          mappedCol.Cell = numberCell;
        break;
      case "details":
        
        
        // Luigi: Link Creation 1/3
        mappedCol.Cell = getDetailCell(mappedCol);
        break;
      case "select":
        mappedCol.Cell = StatusBox;
        mappedCol.minWidth = 50;
        break;
      case null:
        
        
        if (mappedCol.Header === "budget") {
          
          
          mappedCol.Header = "";
          mappedCol.filterable = false;
          mappedCol.Cell = ToBudgetBtn;
          
        }
        
        
        // Luigi: Link Creation 2/3
        
        else if (mappedCol.type.ofType.kind === "LIST") {
          if ((mappedCol.Header === "organizations" && structureName === "Project" && !isProjectManagerPage) || mappedCol.accessor === "structureSubcategories") {
            mappedCol.show = false;
          }
          mappedCol.Header = "";
          mappedCol.filterable = false;
          mappedCol.Cell = !isProjectManagerPage || mappedCol.accessor === "organizations" ? getDetailCell(mappedCol) : ToUnitsBtn;
        }
        break;
      case parentName:
        mappedCol.show = false;
        break;
      case "Structure":
        mappedCol.show = false;
        break;
      case "OrgVersionValidation":
        mappedCol.show = false;
        break;
      case "Dropdown":
        if (mappedCol.hasOwnProperty('api'))
          mappedCol.Cell = leftAlign;
        break;
      case "User":
        mappedCol.show = false;
        break;
      case "Currency":
        // PM20210704
        // if (structureName === "Project")
        //     mappedCol.show = false;
        mappedCol.filterMethod = currencyFilterMethod;
        mappedCol.sortable = true
        mappedCol.sortMethod = setSortMethod()
        mappedCol.Cell = numberCell;
        break;
      case "ValidationRule":
        
        
        // Luigi: Link Creation 3/3
        
        mappedCol.Cell = getDetailCell(mappedCol);
        break;
      case "SubCategory":
        mappedCol.Cell = leftAlign
        break;
      case "Model":
        mappedCol.Cell = linkText
        break;
      case "Project":
        mappedCol.show = false
        break;
      case "OrganizationVersionStatus":
        mappedCol.Cell = leftAlign
        break;
      case "Organization":
        if (mappedCol.type.ofType.kind === "OBJECT") {
          if (structureName === "ProjectUnits") {
            mappedCol.accessor = "organization.description";
          }
        }
        mappedCol.Cell = leftAlign
        break;
      case "ProjectVersion":
        mappedCol.show = false
        break;
      case "clone":
        mappedCol.Header = ""
        mappedCol.Cell = actionBtn
        break;
      case "manageUser":
        mappedCol.Header = ""
        mappedCol.Cell = OpenUserButton
        break;
      default:
        mappedCol.Cell = Cell;
    }
    
    mappedCol.headerStyle = headerStyle;
    
    let filtered_columns;
    
    
    if (detailsBtnLabel === "FiltraProgettiPerStrutturaAnno") {
      
      filtered_columns = [ "id", "description", "validationRules"];
      
      if (filtered_columns.includes(mappedCol.accessor)) {
        
        if (mappedCol.accessor === "id") {
          
          mappedCol.Header = "";
          mappedCol.headerStyle =  {textAlign: 'left'};
          mappedCol.show = true;
          mappedCol.style = {color: 'white'};
          
        }
        
        
        else if (mappedCol.accessor === "description") {
          
          mappedCol.Header = "Anno";
          mappedCol.Cell = leftAlign;
          mappedCol.headerStyle =  {textAlign: 'left',fontWeight: 'bold'};
          mappedCol.show = true;
          
        }
        
        else if (mappedCol.accessor === "validationRules") {
          
          mappedCol.Header = "Mostra Progetti";
          mappedCol.headerStyle =  {textAlign: 'left',fontWeight: 'bold'};
          mappedCol.style = {textAlign: 'left'};
          mappedCol.show = true;
        }
        
        else {
          
          mappedCol.show = true;
          
        }
        
      } else {
        
        mappedCol.show = false;
        
      };
      
    }
    
    if (detailsBtnLabel === "Filtra") {
      
      
      filtered_columns = [ "id", "description", "validationRules"];
      //filtered_columns = [ "description", "validationRules"];
      
      if (filtered_columns.includes(mappedCol.accessor)) {
        
        
        if (mappedCol.accessor === "id") {
          
          mappedCol.Header = "";
          mappedCol.headerStyle =  {textAlign: 'left'};
          mappedCol.show = true;
          mappedCol.style = {color: 'white'};
          
        }
        
        
        else if (mappedCol.accessor === "description") {
          
          mappedCol.Header = "Descrizione";
          mappedCol.headerStyle =  {textAlign: 'left',fontWeight: 'bold'};
          mappedCol.show = true;
          
          
        }
        
        else if (mappedCol.accessor === "validationRules") {
          
          mappedCol.Header = "Mostra Anni Coinvolti";
          mappedCol.headerStyle =  {textAlign: 'left',fontWeight: 'bold'};
          mappedCol.style = {textAlign: 'left'};
          mappedCol.show = true;
          
        } else {
          
          mappedCol.show = true;
          
        }
        
      } else {
        
        mappedCol.show = false;
        
      };
      
    }
    
    
    if (detailsBtnLabel === "Progetti") {
      
      // TODO: MOH        
      // console.log("IMPLEMENTARE");
      
    }
    
    return mappedCol;
    
  };
  
  
  
  
  
  if (structureName === "Project" && isProjectManagerPage) {
    
    const position = schema.findIndex(obj => obj.accessor === "organizations");
    
    
    let new_button = JSON.parse(JSON.stringify(schema[position]));
    new_button.accessor = "budget";
    new_button.Header = "budget";
    
    schema = [
      ...schema.slice(0, position),
      new_button,
      ...schema.slice(position)
    ];
    
  }
  
  // ("ESEGUO MAPPAGGIO DELLO SCHEMA DI TABLELLA");
  
  return setColMapper(history, structureName, schema, colMapper, userRole, isProjectManagerPage);
  
  
};



const GenericListContainer = ({
                                list, structureName, secondLink, budgetLink, secondDetailsBtn, btnLabel, detailsBtnLabel,
                                linkFunction, headers, doSubmit, parentName, parentValue, totalCost, totalCurrentCost, eligibleCost, eligibleCurrentCost,
                                isUploadEnabled, status, inmodal, instCode, isCloned, coFinancig, structureId,
                                isManagerHasUnit, missingheader, code, prj_desc
                              }) => {
  
  //sarebbe da capire cosa fa useContext(ProjectContext);
  //per evitare missingheader ed missingheader2
  
  
  //console.log("structureName: ", structureName);
  
  const { project, setProject } = useContext(ProjectContext);
  

  
  const debug = {
    
    "missingheader": missingheader,
    "project": project,
    "setProject": setProject,
    "structureName": structureName,
    "secondLink": secondLink,
    "secondDetailsBtn": secondDetailsBtn,
    "detailsBtnLabel": detailsBtnLabel,
    "linkFunction": linkFunction,
    "headers": headers,
    "doSubmit": doSubmit,
    "parentName": parentName,
    "parentValue": parentValue,
    "totalCost": totalCost,
    "totalCurrentCost": totalCurrentCost,
    "eligibleCost": eligibleCost,
    "eligibleCurrentCost": eligibleCurrentCost,
    "isUploadEnabled": isUploadEnabled,
    "status": status,
    "inmodal": inmodal,
    "instCode": instCode,
    "isCloned": isCloned,
    "coFinancig": coFinancig,
    "structureId": structureId,
    "isManagerHasUnit": isManagerHasUnit,
    "list": list,
    
  };
  
  

  
  
  //console.log("list :", list);
  //console.log("structureName :", structureName);
  
  const {t} = useTranslation();
  const history = useHistory();
  let {id, orgVersionId, projectVersionId} = useParams();
  const {state} = useLocation();
  const orgVersionInstCode = state?.orgVersionInstCode
  const [modalState, toggleModalState] = useState(false);
  
  const [budgetState, toggleBudgetState] = useState(false);
  
  const [confModalState, toggleConfModalState] = useState(false);
  const [submitModalState, toggleSubmitModalState] = useState(false);
  const [commentModalState, toggleCommentModalState] = useState(false);
  const [manageUserModalState, toggleManageUserModalState] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);
  const [filteredTable, setFiltered] = useState([]);
  const [selectedRows, setRows] = useState([]);
  const [values, setValues] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [spinnerStatus, setSpinnerStatus] = useState(false);
  const [isAlertOpen, toggleAlert] = useState(false);
  const [alertMessage, setMessage] = useState("");
  
  // const [doUpdate] = useMutation(getUpdateMutation(structureName));
  const [doApprove] = useMutation(SETFINANTIALSTATUS, {
    onCompleted(data) {
      setRows([]);
      setValues(null);
      setSpinnerStatus(false)
    }
  });
  const [consolidate] = useMutation(CONSOLIDATE_ORG_VERSION, {
    onCompleted(data) {
      if (data.result) {
        setSpinnerStatus(false);
        
        // dovrebbe andare a http://localhost:8030/totals/5894/16
        // o meglio http://localhost:8030/manager
        
        history.push(MANAGERPAGE, {refetch: true});
      }
    }
  });
  const [snapshot] = useMutation(SNAPSHOT_ORG_VERSION, {
    onCompleted(data) {
      if (data.result) {
        
        setSpinnerStatus(false);
        history.push({
          pathname: `${ORGVERSION}/${projectVersionId}`
        })
        // history.push(PRJ, {refetch: true});
      }
    }
  });
  const [doSubmitMutation] = useMutation(UPDATESTATUS);
  const [doCloneOrg] = useMutation(CLONEORGANIZATION, {
    onCompleted() {
      setIsLoading(false)
    }
  });
  const [doCloneOrgVersionManager] = useMutation(CLONEORGANIZATION, {
    onCompleted() {
      history.push({
        pathname: `${ORGVERSION}/${projectVersionId}`
      })
    }
  });
  
  const [doReopenOrg] = useMutation(UPDATESTATUS, {
    onCompleted() {
      history.push({
        pathname: `${ORGVERSION}/${projectVersionId}`
      })
    }
  });
  const {data: auth} = useQuery(GET_LOGIN_DATA);
  const userRole = auth ? auth.userInfo.role : "";
  const {data: projectInfo} = useQuery(GET_PRJ_INFO);
  
  
  // const {loading: loadingMoreProjectInfo, data: moreProjectInfo, error: errorMoreProjectInfo} = useQuery(FIND_PROJECT, {
  //   variables: { projectId: projectInfo.projectId },
  //   enabled:!!projectInfo.projectId,
  // }); 
  
 
  const {data: structInfo} = useQuery(GET_STRUCTURE_INFO);
  const {financialsApi} = structInfo ? structInfo.structInfo : {financialsApi: null};
  const {data: userInfo} = useQuery(GET_LOGIN_DATA);
  const projectYear = projectInfo ? projectInfo.year - 1 : 0;
  const [doImport, loadingImport] = useMutation(DOIMPORT, getOnCompleteOptions(history));
  const [requestFinancials, loadingFinancials] = useMutation(REQUESTFINANCIALSV2, getOnCompleteOptions(history));
  
  // console.log("structureName: ", structureName);
  
  const {data, loading, error} = useQuery(getStructureQuery(structureName), {
    variables: {id: id, structureName: structureName},
  });
  
  // console.log("data: ", data);
  
  const tableStruct = getTableStructure(structureName, data);
  
  // console.log("tableStruct: ");
  // keyValue(tableStruct);
  
  applyTranslate(tableStruct, t, list, structureName);
  
  const headersName = headers ? headers : [];
  
  // console.log("headersName: ", headersName);
  
  const isProjectManagerPage = history.location.pathname.includes(MANAGERPAGE);
  const isCommentBtnDisabled = ['Approvato', 'Verificato'].includes(status);
  
  if (loading || loadingImport.loading || loadingFinancials.loading) return <Spinner style={{width: '3rem', height: '3rem'}}/>;
  if (error) return <div>{error.message}</div>;
  const lastIndex = list ? list.length : 0;
  if (typeof isUploadEnabled === 'undefined') isUploadEnabled = true;
  var isConsolidated = structureName === "OrganizationVersion" && !!list.find(l => l.status === t(CONSOLIDATED));
  const isVerified = structureName === "OrganizationVersion" && !!list.find(l => l.status === t(VERIFIED));
  
  
  let test_assign_from_jsx;
  
  let progetto_in_header;
  let istituto_in_header;
  
  
  if (missingheader != undefined &&
    missingheader.length > 0) {
    
    progetto_in_header = missingheader.indexOf("\n") > 0 ? missingheader.substring(0, missingheader.indexOf("\n")) : missingheader;
    istituto_in_header = missingheader.indexOf("\n") > 0 ? missingheader.substring( 1 + missingheader.indexOf("\n")) : "";
    
  } else if (project != undefined &&
    project.length > 0) {
    
    progetto_in_header = project.indexOf("\n") > 0 ? project.substring(0, project.indexOf("\n")) : project;
    istituto_in_header = project.indexOf("\n") > 0 ? project.substring( 1 + project.indexOf("\n")) : "";
    
  }
  
  return (
    <Container fluid>
      {isAlertOpen && <AlertComponent color={"danger"} isAlertOpen={isAlertOpen} message={alertMessage}
                                      toggleAlert={toggleAlert}/>}
      
      
      {
        
        <div className={styles.hidden}>{test_assign_from_jsx = undefined}</div>
        
      }
      
      {
        
        prj_desc != undefined &&
        <Row>
          
          <Col xs={6}><div className={styles.head_k_b}>&nbsp;</div></Col>
          <Col xs={1}><div className={styles.head_k_b}>Tipologia:</div></Col>
          <Col xs={3}><div className={styles.head_v}>{prj_desc}</div></Col>
          <Col></Col>
        </Row>
        
      }
      
      {
        
        missingheader != undefined &&
        missingheader.length > 0 &&
        <Row>
          <Col xs={1}><div className={styles.head_k_b}>&nbsp;</div></Col>
          <Col xs={3}><div className={styles.head_v_b}>{progetto_in_header}</div></Col>
          <Col xs={1}><div className={styles.head_k_b}>Istituto:</div></Col>
          <Col xs={6}><div className={styles.head_v_b}>{capitalize(istituto_in_header)} &nbsp; {'('} {parentValue ? t(parentValue) : t(structureName)} {')'}</div></Col>
          
          <Col></Col>
        </Row>
        
      }
      
      {
        
        missingheader != undefined &&
        missingheader.length > 0 &&
        <Row>
          <Col xs={1}><div>&nbsp;</div></Col>
        </Row>
        
      }
      
      
      {
        
        parentValue !== 'managerPage' &&
        project.length > 0 &&
        <Row>
          <Col xs={1}><div className={styles.head_k_b}>&nbsp;</div></Col>
          <Col xs={2}><div className={styles.head_v_b}>{progetto_in_header}</div></Col>
          <Col xs={1}><div className={styles.head_k_b}>Istituto:</div></Col>
          <Col xs={4}><div className={styles.head_v_b}>{capitalize(istituto_in_header)} &nbsp; {'('} {parentValue ? t(parentValue) : t(structureName)} {')'}</div></Col>
          
          {
            coFinancig > 0 &&
            <>
              <Col xs={2}><div className={styles.head_k}>Finanziamento:</div></Col>
              <Col xs={2}><div className={styles.head_v}>
                <NumberFormat
                  thousandSeparator={"."} prefix={'€ '} decimalSeparator={','}
                  value={parseFloat(coFinancig)} decimalScale={2} fixedDecimalScale={true} displayType={'text'}
                  disabled
                />
              </div>
              </Col>
            </>
          }
          
          <Col></Col>
        </Row>
        
      }
      
      
      {
        
        parentValue !== 'managerPage' &&
        project.length > 0 &&
        <Row>
          <Col xs={1}><div>&nbsp;</div></Col>
          <Col></Col>
        </Row>
        
      }
      
      
      {
        
        totalCost > 0 &&
        <Row>
          <Col xs={2}><div>&nbsp;</div></Col>
          <Col xs={2}><div className={styles.head_k}>Costo Totale:</div></Col>
          <Col xs={2}><div className={styles.head_v}>
            <NumberFormat
              thousandSeparator={"."} prefix={'€ '} decimalSeparator={','}
              value={parseFloat(totalCost)} decimalScale={2} fixedDecimalScale={true} displayType={'text'}
              disabled
            />
          </div>
          </Col>
          
          
          <Col xs={2}><div className={styles.head_k}>Costo Eleggibile:</div></Col>
          <Col xs={2}><div className={styles.head_v}>
            <NumberFormat
              thousandSeparator={"."} prefix={'€ '} decimalSeparator={','}
              value={parseFloat(eligibleCost)} decimalScale={2} fixedDecimalScale={true} displayType={'text'}
              disabled
            />
          </div>
          </Col>
          
          
          <Col></Col>
        </Row>
        
      }
      
      
      {
        
        totalCurrentCost > 0 &&
        false &&
        <Row>
          <Col xs={4}><div>&nbsp;</div></Col>
          <Col xs={3}><div className={styles.redkey}>Costo Totale Corrente:</div></Col>
          <Col xs={2}><div className={styles.value}>
            <NumberFormat
              thousandSeparator={"."} prefix={'€ '} decimalSeparator={','}
              value={parseFloat(totalCurrentCost)} decimalScale={2} fixedDecimalScale={true} displayType={'text'}
              disabled
            />
          </div>
          </Col>
          <Col></Col>
        </Row>
        
      }
      
      
      {
        
        eligibleCost > 0 &&
        <Row>
          <Col xs={2}><div>&nbsp;</div></Col>
          
          
          <Col xs={2}><div className={styles.head_k}>Corrente:</div></Col>
          <Col xs={2}><div className={styles.head_v}>
            <NumberFormat
              thousandSeparator={"."} prefix={'€ '} decimalSeparator={','}
              value={parseFloat(totalCurrentCost)} decimalScale={2} fixedDecimalScale={true} displayType={'text'}
              disabled
            />
          </div>
          </Col>
          
          
          <Col xs={2}><div className={styles.head_k}>Corrente:</div></Col>
          <Col xs={2}><div className={styles.head_v}>
            <NumberFormat
              thousandSeparator={"."} prefix={'€ '} decimalSeparator={','}
              value={parseFloat(eligibleCurrentCost)} decimalScale={2} fixedDecimalScale={true} displayType={'text'}
              disabled
            />
          </div>
          </Col>
          
          
          <Col></Col>
        </Row>
        
      }
      
      
      {
        
        eligibleCurrentCost > 0 &&
        false &&
        <Row>
          <Col xs={4}><div>&nbsp;</div></Col>
          <Col xs={3}><div className={styles.redkey}>Costo Eleggibile Corrente:</div></Col>
          <Col xs={2}><div className={styles.value}>
            <NumberFormat
              thousandSeparator={"."} prefix={'€ '} decimalSeparator={','}
              value={parseFloat(eligibleCurrentCost)} decimalScale={2} fixedDecimalScale={true} displayType={'text'}
              disabled
            />
          </div>
          </Col>
          <Col></Col>
        </Row>
        
      }
      
      
      {
        
        coFinancig > 0 &&
        false &&
        <Row>
          <Col xs={4}><div>&nbsp;</div></Col>
          <Col xs={3}><div className={styles.redkey}>Finanziamento:</div></Col>
          <Col xs={2}><div className={styles.value}>
            <NumberFormat
              thousandSeparator={"."} prefix={'€ '} decimalSeparator={','}
              value={parseFloat(coFinancig)} decimalScale={2} fixedDecimalScale={true} displayType={'text'}
              disabled
            />
          </div>
          </Col>
          <Col></Col>
        </Row>
        
      }
      
      {
        
        orgVersionId && false &&
        <Row>
          <Col></Col>
          <Col xs lg="2">
            <NavLink href={`${DOWNLOAD_URL}${orgVersionId}/pdf-validazione?token=${token}`}
                     target="_blank"><span
              className={styles.text}>Scarica Pdf Validazione {orgVersionId}</span></NavLink>
          </Col>
        </Row>
        
      }
      
      {
        
        (structureName === "Financial" && ROLES_TO_SEE_STATUS.includes(userRole)) &&
        <Row>
          <Col xs={1}><div>&nbsp;</div></Col>
        </Row>
        
      }
      
      
      <Row>
        <Col xs="2" hidden={checkStatus(status)}>
          
          {(structureName !== "Structure" && structureName !== "ProjectVersion" && structureName !== "ProjectUnits" && structureName !== "OrganizationVersion" && structureName !== "StructureMOHStructure") &&
            <div hidden={structureName === "Model"}>
              <ModalContainer doSubmit={doSubmit} label={btnLabel} structure={data} lastIndex={lastIndex}
                              structureName={structureName} modalState={modalState}
                              values={values}
                              setValues={setValues}
                              toggleModalState={toggleModalState}
                              isUploadEnabled={isUploadEnabled}
                              doImport={doImport}/>
            </div>}
          {structureName === "Model" &&
            <Button onClick={() => history.push(`${ITEM}/${id}`)}>Inserimento</Button>
          }
          {structureName  === "ProjectVersion" &&
            <ProjectVersionModal
              toggleModal={toggleModalState}
              modalState={modalState}
              selectedRows
              list={list}
              translate={t}
            />}
          
          {structureName  === "ProjectUnits" &&
            <ProjectUnitModal
              toggleModal={toggleModalState}
              modalState={modalState}
              values={values}
              list={list}
              translate={t}
              setValues={setValues}
            />}
          
          {(!isUploadEnabled && structureName === "Financial") &&
            <Button onClick={async () => {
              try {
                await getFinancialRequest(requestFinancials, id, orgVersionId, userInfo, projectInfo,
                  financialsApi, doImport, projectYear, orgVersionInstCode);
              } catch (e) {
                console.log("Error", e)
              }
            }}>
              Importa
            </Button>}
          {((structureName === "OrganizationVersion") && isManagerHasUnit) &&
            <Button disabled={isLoading} color="danger"
                    onClick={async () => {
                      try {
                        setIsLoading(true)
                        await consolidate({
                          variables: {
                            projectVersionId: projectVersionId
                          }
                        });
                      } catch (e) {
                        setIsLoading(false)
                        toggleAlert(true)
                        setMessage(t("GENERIC_ERROR")) // needs to use apollo error link
                      }
                    }}>
              {t("cons")}
            </Button>}
        
        </Col>
        <Col xs="2">
          
          {((structureName === "OrganizationVersion") && isManagerHasUnit) &&
            <Button disabled={isLoading} color="warning"
                    onClick={async () => {
                      try {
                        setIsLoading(true)
                        await snapshot({
                          variables: {
                            projectVersionId: projectVersionId
                          }
                        });
                      } catch (e) {
                        setIsLoading(false)
                        toggleAlert(true)
                        setMessage(t("GENERIC_ERROR")) // needs to use apollo error link
                      }
                    }}>
              {t("snapshot")}
            
            </Button>}
        </Col>
        
        
        
        {(structureName === "Financial" && ROLES_TO_SEE_STATUS_RESTRICTED.includes(userRole)) && <Col>
          <Button disabled={!selectedRows.length || isCommentBtnDisabled} className={styles.circleBtn} size='lg'
                  onClick={() => {
                    setValues({
                      rowId: selectedRows,
                      modelId: id,
                      orgVersionId: orgVersionId,
                      status: REJECTED
                    });
                    toggleCommentModalState(true);
                  }} title={t("changeSelectedStatus")}/>{" "}
          <Button size='lg' color='success' className={styles.circleBtn} disabled={selectedRows.length > 0 || isCommentBtnDisabled}
                  onClick={() => changeStatus(setSpinnerStatus, doApprove, getNonStatuses(list)
                    , id, orgVersionId, APPROVED)} title={t("approveAll")}>
          </Button>
          {" "}
          <ButtonGroup>
            <Button size='sm' disabled={isCommentBtnDisabled} onClick={() => handleSelectAll(filteredTable, setRows, selectedRows)}>
              {selectedRows.length ? t("deselectAll") : t("selectAll")}
            </Button>
          </ButtonGroup>
        </Col>}
        <Col>
          {totalCost > 0 && false &&<h3 className={styles.h3}>Costo Totale: <NumberFormat
            thousandSeparator={"."} prefix={'€ '} decimalSeparator={','}
            value={parseFloat(totalCost)} decimalScale={2} fixedDecimalScale={true} displayType={'text'}
            disabled
          /></h3>}
          {totalCurrentCost > 0 && false &&<h3 className={styles.h4}>Corrente: <NumberFormat
            thousandSeparator={"."} prefix={'€ '} decimalSeparator={','}
            value={parseFloat(totalCurrentCost)} decimalScale={2} fixedDecimalScale={true} displayType={'text'}
            disabled
          /></h3>}
        </Col>
        
        {
          
          false && <Col>
            <h3 className={styles.h3}>x{`${parentValue ? t(parentValue) : t(structureName)}`}</h3>
          
          </Col>
          
        }
        
        {/*<pre className={styles.left}>{JSON.stringify(debug, null, 2)}</pre>*/}
        
        <Col>
          {eligibleCost > 0 && false && <h3 className={styles.h3}>Costo Eleggibile: <NumberFormat
            thousandSeparator={"."} prefix={'€ '} decimalSeparator={','}
            value={parseFloat(eligibleCost)} decimalScale={2} fixedDecimalScale={true} displayType={'text'}
            disabled/></h3>}
          {eligibleCurrentCost > 0 && false && <h3 className={styles.h4}>Corrente: <NumberFormat
            thousandSeparator={"."} prefix={'€ '} decimalSeparator={','}
            value={parseFloat(eligibleCurrentCost)} decimalScale={2} fixedDecimalScale={true} displayType={'text'}
            disabled/></h3>}
        </Col>
        <Col>
          {coFinancig > 0 && false && <h3 className={styles.h3}>Finanziamento: <NumberFormat
            thousandSeparator={"."} prefix={'€ '} decimalSeparator={','}
            value={parseFloat(coFinancig)} decimalScale={2} fixedDecimalScale={true} displayType={'text'}
            disabled/></h3>}
        </Col>
      </Row>
      <Row md={12}>
        <Col>
          <GenericListComponent
            columns={prepare(setValues,
              toggleBudgetState,
              toggleModalState,
              doApprove,
              structureName,
              tableStruct,
              detailsBtnLabel,
              linkFunction,
              secondLink,
              secondDetailsBtn,
              budgetLink,
              headersName,
              parentName,
              toggleConfModalState,
              isUploadEnabled,
              toggleSubmitModalState,
              status,
              setRows,
              selectedRows,
              toggleCommentModalState,
              userRole,
              spinnerStatus,
              t,
              doSubmitMutation, doCloneOrg, instCode, setIsLoading, history, isProjectManagerPage,
              doCloneOrgVersionManager,
              doReopenOrg,
              isConsolidated,
              isVerified,
              toggleManageUserModalState
            )}
            data={list}
            setFiltered={setFiltered}
            inmodal={inmodal}
            loading={isLoading}
            code={code}
            retainFilters={structureName !== "Financial"}
          />
        </Col>
      </Row>
      
      <Col hidden>
        <ConfirmationModal isModalOpen={confModalState} toggleModal={toggleConfModalState}
                           structureName={structureName} values={values} setValues={setValues}
                           doSubmitMutation={doSubmitMutation} setIsLoading={setIsLoading}
                           isStatusLoading={isLoading}/>
      </Col>
      {submitModalState && <Col hidden>
        <SubmitModal isModalOpen={submitModalState} toggleModal={toggleSubmitModalState} values={values}/>
      </Col>}
      {structureName === "Financial" && <Col hidden>
        <ReasonCodeModal readOnly={false} isModalOpen={commentModalState} toggleModal={toggleCommentModalState}
                         rowValues={values} changeStatus={changeStatus}
                         doApprove={doApprove} translate={t} userRole={userRole} nestedModal={nestedModal}
                         setNestedModal={setNestedModal} closeAll={closeAll} setCloseAll={setCloseAll}
                         setSpinnerStatus={setSpinnerStatus} isCloned={isCloned} status={status}/>
      </Col>}
      {(structureName === "SubCategoryTotalDto" && commentModalState) && <Col hidden>
        <CommentModal isModalOpen={commentModalState} toggleModal={toggleCommentModalState}
                      rowValues={values} translate={t} values={values} userRole={userRole}/>
      </Col>}
      {(structureName === "ProjectUnits" && manageUserModalState) && <Col hidden>
        <ManageUserModal instituteCode={manageUserModalState} toggleModal={toggleManageUserModalState}
                         rowValues={values} translate={t} values={values} userRole={userRole}/>
      </Col>}
      
      
      {budgetState && <Col hidden>
        <BudgetModal
          toggleBudgetState={toggleBudgetState}
          projectId={budgetState}
          
        />
      </Col>}
      
      
    </Container>
  );
};

export default GenericListContainer

